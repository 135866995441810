import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoAdd, IcoArrowRigth, IcoCalendar, IcoCog, IcoHelpCircle, IcoProjeto1, IcoProjeto2, IcoProjeto3, IcoProjeto4, List, LoadingContent, MaskCpf, MaskData, Popover, dateCustom } from '../../components'

import './meusProjetos.scss'
import { ativarInativaprojeto, listarMeusProjetos, putListMeuProjeto, searchMovie } from './redux/meusProjetosActions'
import { useEffect } from 'react'
import Criar from './criar/criar-old'
import { modalOpen } from '../../layout/redux/layoutActions'
import { iconeprojeto, iconesList, typeStatusCustom } from './redux/meusProjetos'
import { IF, history } from '../../helpers'
import ObterMais from './obterMais'

export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { listMeusProjetos, paginate } = useSelector(state=> state.meusProjetosState)

  useEffect(()=> {
    // dispatch(listarMeusProjetos())
  }, [sistema.idioma])

  const headerDefault = [
    {
      column: 'projetoCuston',
      text: nls.listarProjetos.tableProjetos,
      className: '',
    },
    {
      column: 'tipo',
      text: nls.listarProjetos.tableTipo,
      sort: '',
      className: '',
    },
    {
      column: 'segmentoDoProjeto',
      text: nls.listarProjetos.tableSegmento,
      sort: '',
      className: '',
    },
    {
      column: 'statusCuston',
      text: nls.listarProjetos.tableStatus,
      sort: '',
      className: '',
    },
    {
      column: 'licencaDataCuston',
      text: nls.listarProjetos.tableLicenca,
      sort: '',
      className: '',
    },
    {
      column: 'actions',
      text: '',
      sort: '',
      className: 'actions onlysvg',
    }
  ]

  const situacaoProjeto = [
    {id: 'A', name: nls.situacaoA},
    {id: 'I', name: nls.situacaoI},
    {id: 'AI', name: nls.situacaoAI},
  ]

  const handlePaginate = e => {
    console.warn(e, 'handlePaginate');
  }

  const handlePerPage = e => {
    console.warn(e, 'handlePerPage');
  }

  const handleConfigurar = e => {
    history.push(`/meusprojetos/${e.idProjeto}/configurar`)
  }

  const handleVizualizar = e => {
    history.push(`/meusprojetos/${e.idProjeto}/resumoprojeto`)
  }

  const handleMudaStatus = e => {
    if (e.permissaoAlterarStatusProjeto) {
      if (!e.statusBolean) {
        if (sistema?.creditoConta?.creditoProjetos) {
          dispatch(ativarInativaprojeto(e))
        } else{
          dispatch(modalOpen('obter-mais'))
        }
      } else {
        dispatch(ativarInativaprojeto(e))
      }
    }
  }

  const handleCriar = e => {
    if (sistema?.creditoConta?.creditoProjetos) {
      dispatch(modalOpen('criarMeuProjeto'))
    } else{
      dispatch(modalOpen('obter-mais'))
    }
  }
  
  return (
    <div className='box-meus-projetos'>
      <div className='box-content'>
        <div className='box-content-topinfo'>
          <h3>{nls.titleList} <Popover>{nls.mensagem.titlePopover}</Popover></h3>
          <div>
            <IF se={usuario?.permissoes?.CriarNovoProjeto}>
              <Button
                color='primary circle'
                onClick={()=> handleCriar()}
              ><IcoAdd /> {nls.criar}</Button>
            </IF>
          </div>
        </div>
        <LoadingContent
          show={false}
        >
          <List
            header={headerDefault}
            data={listMeusProjetos}
            listCustom={e=> {
              const item = e
              const statusInfo = typeStatusCustom(e.status)

              const statusAction = a =>{
                if (a.situacao !== 'AI') {
                  handleMudaStatus({...a, statusBolean: statusInfo.status})
                }
              }

              item.projetoCuston = <div className='info-projeto'>
                <div >{iconeprojeto(e?.icone)}</div>
                <div>
                  <label>{e.descricao}</label>
                  <p>{e.descricaoLonga}</p>
                </div>
              </div>
              item.statusCuston = <span style={{cursor: 'pointer'}} className={statusInfo?.class} onClick={()=> statusAction(e)}>{nls[`situacao${e.situacao}`]}</span>
              // e.status?nls.ativo:nls.inativo
              item.licencaDataCuston = <span><IcoCalendar /> {MaskData(e.dataCriacao, '', sistema.idioma)}</span>
              item.actions = <div>
                <Button
                  type='link'
                  onClick={()=> handleConfigurar(e)}
                  disabled={!e.permissaoEditarProjeto || e.situacao === 'I'}
                ><IcoCog /></Button>
                <Button
                  type='link'
                  color='primary'
                  onClick={()=> handleVizualizar(e)}
                  disabled={e.situacao === 'I'}
                ><IcoArrowRigth /></Button>
              </div>

              return item
            }}
            // paginate={paginate}
            // actionPaginate={(e)=> handlePaginate(e)}
            // handlePerPage={(e)=> handlePerPage(e)}
            // nls={nls.paginacao}
          />
        </LoadingContent>
      </div>
      <Criar />
      <ObterMais type='conteudoProjetos'/>
    </div>
  )
}
